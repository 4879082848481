import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo/Logo.svg";
import { FOOTER_NAV } from "../constants/navRoutes";
import { FaFacebookF } from "react-icons/fa";
import { BsTwitter, BsInstagram, BsYoutube, BsTelephone } from "react-icons/bs";
import { HiOutlineLocationMarker, HiOutlineMail } from "react-icons/hi";
import { MdOutlineWatchLater } from "react-icons/md";

const Footer = () => {
	const location = useLocation();
	return (
		<div className="w-full bg-troo-primaryDark">
			<div className="main-container w-full pt-[60px] flex flex-col gap-12 items-center justify-between">
				<div className="relative flex flex-col z-10 items-center w-full justify-center">
					<div className="flex flex-col xl:flex-row w-full z-[1] text-troo-primaryDark xl:items-center justify-between rounded-xl bg-troo-light gap-4 p-8 md:p-12">
						<div className="flex flex-col items-start">
							<h4 className="font-normal">Get Free Consultancy</h4>
							<h4>Call (+44) 123 456 7890</h4>
						</div>
						<div className="flex flex-col lg:flex-row lg:items-center gap-6">
							<input
								type="text"
								className="troo-input bg-transparent"
								placeholder="Enter Your Name"
							/>
							<input
								type="text"
								className="troo-input bg-transparent"
								placeholder="Enter Your Email"
							/>
							<button className="font-semibold py-4 px-7 bg-troo-primaryDark text-troo-light rounded-xl">
								SUBMIT
							</button>
						</div>
					</div>
					<div className="absolute w-full transform scale-[.98] -bottom-3 left-0 h-full bg-troo-secondary rounded-xl -z-[1]"></div>
				</div>
				<div className="w-full flex xl:flex-row flex-col items-center justify-between gap-6 border-b border-troo-secondary border-opacity-20 py-8">
					<img src={Logo} alt="logo" className="w-56" />
					<div className="flex flex-wrap items-center justify-center gap-10">
						<div className="flex w-full lg:flex-row flex-col lg:w-auto items-center text-center gap-8 h-full ml-4 lg:ml-0">
							{FOOTER_NAV.map((route, i) => {
								return (
									<Link
										key={i}
										to={route.path}
										className={`${
											location.pathname === route.path && "activeNav"
										} relative h-full flex items-center text-center justify-center text-troo-light text-sm lg:text-base`}
									>
										{route.name}
									</Link>
								);
							})}
						</div>
					</div>
					<div className="flex items-center gap-3">
						<div className="flex items-center justify-center p-3 text-troo-light border border-troo-secondary border-opacity-20 rounded-full text-sm lg:text-lg">
							<FaFacebookF />
						</div>
						<div className="flex items-center justify-center p-3 text-troo-light border border-troo-secondary border-opacity-20 rounded-full text-sm lg:text-lg">
							<BsTwitter />
						</div>
						<div className="flex items-center justify-center p-3 text-troo-light border border-troo-secondary border-opacity-20 rounded-full text-sm lg:text-lg">
							<BsInstagram />
						</div>
						<div className="flex items-center justify-center p-3 text-troo-light border border-troo-secondary border-opacity-20 rounded-full text-sm lg:text-lg">
							<BsYoutube />
						</div>
					</div>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3 w-full">
					<div className="w-full px-5 py-5 text-troo-light flex items-start gap-3 border border-transparent hover:border-troo-secondary transition-all hover:border-opacity-20 rounded-xl">
						<div className="p-4 bg-troo-secondary bg-opacity-5 rounded-full text-2xl">
							<BsTelephone />
						</div>
						<div className="flex flex-col items-start gap-2">
							<h4 className="">Call us on :</h4>
							<p>+44 123 456 7890</p>
							<p>+44 123 456 7890</p>
						</div>
					</div>
					<div className="w-full px-5 py-5 text-troo-light flex items-start gap-3 border border-transparent hover:border-troo-secondary transition-all hover:border-opacity-20 rounded-xl">
						<div className="p-4 bg-troo-secondary bg-opacity-5 rounded-full text-2xl">
							<HiOutlineMail />
						</div>
						<div className="flex flex-col items-start gap-2">
							<h4 className="">Email Us on:</h4>
							<p>info@troo.com</p>
							<p>Contact@troo.com</p>
						</div>
					</div>
					<div className="w-full px-5 py-5 text-troo-light flex items-start gap-3 border border-transparent hover:border-troo-secondary transition-all hover:border-opacity-20 rounded-xl">
						<div className="p-4 bg-troo-secondary bg-opacity-5 rounded-full text-2xl">
							<HiOutlineLocationMarker />
						</div>
						<div className="flex flex-col items-start gap-2">
							<h4 className="">Visit Us:</h4>
							<p>Ewingar Road Lionsville, New South Wales</p>
						</div>
					</div>
					<div className="w-full px-5 py-5 text-troo-light flex items-start gap-3 border border-transparent hover:border-troo-secondary transition-all hover:border-opacity-20 rounded-xl">
						<div className="p-4 bg-troo-secondary bg-opacity-5 rounded-full text-2xl">
							<MdOutlineWatchLater />
						</div>
						<div className="flex flex-col items-start gap-2">
							<h4 className="">Office Hours:</h4>
							<p>Mon – Fri: 09am – 08pm</p>
							<p>Sat – Sun: Closed</p>
						</div>
					</div>
				</div>
				<div className="w-full text-sm text-troo-light py-8 border-t border-troo-secondary border-opacity-20 mt-8 flex md:flex-row flex-col gap-3 items-center justify-between">
					<p>Copyright © 2023. troothemes. All rights reserved.</p>
					<div className="flex gap-3 items-center">
						<p>Terms & Condition</p>
						<p>Privacy Policy</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
