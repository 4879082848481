import React from 'react'
import Heading from '../components/Heading'
import GalaryCard from '../components/GalaryCard'
import Img1 from "../../assets/images/galary/1.png"
import Img2 from "../../assets/images/galary/2.png"
import Img3 from "../../assets/images/galary/3.png"
import Img4 from "../../assets/images/galary/4.png"
import Img5 from "../../assets/images/galary/5.png"
import { ROUTES } from '../constants/routes'
import { Link } from 'react-router-dom'

const GalarySection = ({isListingPage}) => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full py-[100px] flex flex-col items-center gap-12">
        {
          !isListingPage && (
            <Heading align="center" headline="Recent Works" heading={ 
              <>
                Trusted and reliable <br/> printing solutions
              </>
            } />
          )
        }
        {
          !isListingPage && (
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6">
              <GalaryCard img={Img1} category="Print, brochure" title="Brochure for children education" />
              <GalaryCard img={Img2} category="Cards" title="Custom Cards" />
            </div>
          )
        }
        <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-6">
          <GalaryCard img={Img3} category="Printing" title="T-Shirts" />
          <GalaryCard img={Img4} category="Cards" title="Custom Cards" />
          <GalaryCard img={Img5} category="Cards" title="Custom Cards" />
          {
            isListingPage && (
              <>
                <GalaryCard img={Img3} category="Printing" title="T-Shirts" />
                <GalaryCard img={Img4} category="Cards" title="Custom Cards" />
                <GalaryCard img={Img5} category="Cards" title="Custom Cards" />
                <GalaryCard img={Img3} category="Printing" title="T-Shirts" />
                <GalaryCard img={Img4} category="Cards" title="Custom Cards" />
                <GalaryCard img={Img5} category="Cards" title="Custom Cards" />
                
              </>
            )
          }
        </div>
        {
          !isListingPage && (
            <Link to={ROUTES.recentWork}>
              <button className="btn-outlined-dark font-light">View All Works</button>
            </Link>
          )
        }
      </div>
    </div>
  )
}

export default GalarySection