import React from 'react'
import Heading from '../components/Heading'
import banner from "../../assets/images/offerBanner.png"
import { BsCheckCircleFill } from 'react-icons/bs'
import { motion } from "framer-motion";

const OfferSection = () => {
  return (
    <div className="w-full bg-troo-primaryDark bg-offer-banner bg-center bg-no-repeat">
      <div className="relative main-container w-full py-[100px] grid grid-cols-1 lg:grid-cols-2 justify-between gap-12">
        <div className="flex flex-col items-start gap-4">
          <Heading headline="We Have offer for you." isDark heading={ 
            <>
              Your Brand or Design on <br/> T-Shirts, Bags & More.
            </>
          } />
          <div className="flex items-start gap-3">
            <div className="h-full w-[2px] bg-troo-primaryLight">
              <div className="h-[50%] w-full bg-troo-secondary"></div>
            </div>
            <p className="text-troo-light text-sm font-light">We got you covered with all your printing needs.Select from our compreh ensive list of printing options for your B&W and color copies which best describe your finished product:</p>
          </div>
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
            <div className="flex items-center gap-2 text-troo-light">
              <div>
                <BsCheckCircleFill className="text-lg text-troo-secondary rounded-full"/>
              </div>
              <p className="text-sm font-light">Printed in full-color</p>
            </div>
            <div className="flex items-center gap-2 text-troo-light">
              <div>
                <BsCheckCircleFill className="text-lg text-troo-secondary rounded-full"/>
              </div>
              <p className="text-sm font-light">Double-sided</p>
            </div>
            <div className="flex items-center gap-2 text-troo-light">
              <div>
                <BsCheckCircleFill className="text-lg text-troo-secondary rounded-full"/>
              </div>
              <p className="text-sm font-light">Double-sided</p>
            </div>
            <div className="flex items-center gap-2 text-troo-light">
              <div>
                <BsCheckCircleFill className="text-lg text-troo-secondary rounded-full"/>
              </div>
              <p className="text-sm font-light">Variety of paper sizes</p>
            </div>
            <div className="flex items-center gap-2 text-troo-light">
              <div>
                <BsCheckCircleFill className="text-lg text-troo-secondary rounded-full"/>
              </div>
              <p className="text-sm font-light">Optional finishing</p>
            </div>
            <div className="flex items-center gap-2 text-troo-light">
              <div>
                <BsCheckCircleFill className="text-lg text-troo-secondary rounded-full"/>
              </div>
              <p className="text-sm font-light">Optional finishing</p>
            </div>
          </div>
          <button className="btn-filled mt-3">Get Started Now!</button>
        </div>
        <motion.div initial={{ scale: 0.7 }}
			whileInView={{ scale: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className="h-auto lg:h-[620px] lg:absolute -bottom-20 right-5 xl:right-32">
          <img src={banner} alt="banner" className="h-full object-contain"/>
        </motion.div>
      </div>
    </div>
  )
}

export default OfferSection