import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { ROUTES } from "./shared/constants/routes";
import About from "./pages/About";
import OurServices from "./pages/OurServices";
import OurTeam from "./pages/OurTeam";
import RecentWork from "./pages/RecentWork";
import Testimonial from "./pages/Testimonial";
import Blogs from "./pages/Blogs";
import Pricing from "./pages/Pricing";
import Faqs from "./pages/Faqs";
import Contact from "./pages/Contact";
import NotFound from "./pages/404";
import ComingSoon from "./pages/ComingSoon";
import ServiceDetails from "./pages/ServiceDetails";
import WorkDetails from "./pages/WorkDetails";
import TeamDetails from "./pages/TeamDetails";
import BlogDetails from "./pages/BlogDetails";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={ROUTES.home} element={<Home />} />
				<Route path={ROUTES.aboutUs} element={<About />} />
				<Route path={ROUTES.ourServices} element={<OurServices />} />
				<Route path={ROUTES.ourTeam} element={<OurTeam />} />
				<Route path={ROUTES.recentWork} element={<RecentWork />} />
				<Route path={ROUTES.testimonial} element={<Testimonial />} />
				<Route path={ROUTES.blogsGrid} element={<Blogs />} />
				<Route path={ROUTES.pricing} element={<Pricing />} />
				<Route path={ROUTES.faq} element={<Faqs />} />
				<Route path={ROUTES.contactPage} element={<Contact />} />
				<Route path={ROUTES.errorPage} element={<NotFound />} />
				<Route path={ROUTES.comingSoon} element={<ComingSoon />} />
				<Route path={ROUTES.serviceDetails} element={<ServiceDetails />} />
				<Route path={ROUTES.portfolioDetails} element={<WorkDetails />} />
				<Route path={ROUTES.teamDetails} element={<TeamDetails />} />
				<Route path={ROUTES.blogDetails} element={<BlogDetails />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
