import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import BlogSection from '../shared/sections/BlogSection'
import ClientBrands from '../shared/sections/ClientBrands'

const Blogs = () => {
  return (
    <Layout>
      <PageHeaderSection title="Read From Our Blog"/>
      <BlogSection isListingPage />
      <ClientBrands/>
		</Layout>
  )
}

export default Blogs