import React from 'react'
import notFoundImg from "../assets/images/404.svg"
const NotFound = () => {
  return (
    <div className="w-full min-h-screen bg-troo-light bg-404-back">
      <div className="main-container text-center  w-full h-full p-12 flex flex-col items-center gap-6 justify-center">
        <h3 className="text-troo-primaryDark font-semibold text-2xl">Oops! That page can’t be found.</h3>
        <img src={notFoundImg} alt="404"/>
        <p className="text-troo-tertiary text-sm md:text-base">The page you look for does not exist or another error occured<br/>
          please kindly refreash or retun to home page.</p>
        <div className='flex items-center gap-4'>
          <button className='btn-rect-filled'>Go to Hoempage</button>
          <button className='btn-rect-outlined'>Go Back</button>
        </div>
      </div>
    </div>
  )
}

export default NotFound