import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ClientReviewSection from '../shared/sections/ClientReviewSection'
import ClientBrands from '../shared/sections/ClientBrands'

const Testimonial = () => {
  return (
    <Layout>
			<PageHeaderSection title="Owesome Client’s Feedback"/>
      <ClientReviewSection isListingPage />
      <ClientBrands/>
		</Layout>
  )
}

export default Testimonial