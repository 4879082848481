import React from 'react'
import { FaQuoteLeft, FaStar } from 'react-icons/fa'
import img1 from "../../assets/images/client/image.png"
import { motion } from "framer-motion";

const ReviewCard = ({ title, desc, authorName, author }) => {
  return (
    <motion.div
      initial={{ y: 100 }}
			whileInView={{ y: 1 }}
      transition={{ duration: 1, type: "spring", stiffness: 100 }}
      className='flex flex-col items-center w-full'>
      <div className='w-full bg-troo-gray p-6 md:p-9 flex flex-col gap-4 items-start'>
        <div className='flex items-center justify-between pb-4 border-b-2 border-troo-primaryDark border-opacity-10 w-full'>
          <div className='flex items-center gap-1 text-troo-secondary text-xl'>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
            <FaStar/>
          </div>
          <FaQuoteLeft className='text-5xl text-troo-primaryDark'/>
        </div>
        <div className='flex flex-col items-start gap-3'>
          <h5 className='text-troo-primaryLight text-2xl'>Awesome Services!</h5>
          <p className='text-troo-primary text-troo-primaryLight font-light'>
            Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do eiusmod tempor.
          </p>
        </div>
      </div>
      <div className="p-6 md:p-9 w-full flex items-center gap-3">
        <div className="relative z-10">
          <img src={img1} alt="img1" className='aspect-square' />
          <div className="absolute top-1 left-1 w-full -z-10 h-full rounded-full bg-troo-secondary"></div>
        </div>
        <div className="flex flex-col items-start">
          <h4 className="text-xl md:text-2xl text-troo-primaryDark">Jessica Albert</h4>
          <p className='text-troo-primaryDark font-light'>Bilbord Printing</p>
        </div>
      </div>
    </motion.div>
  )
}

export default ReviewCard