/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { motion } from "framer-motion";
import { ROUTES } from '../constants/routes';
import { Link } from 'react-router-dom';

const TeamCard = ({img, designation, title}) => {
  return (
    <motion.div
      initial={{ y: 100 }}
			whileInView={{ y: 1 }}
      transition={{ duration: 1, type: "spring", stiffness: 100 }}
      className="w-full flex flex-col items-center">
      <div className="relative w-full group">
        <img src={img} alt="img1" className='w-full h-full object-cover object-center' />
        <motion.div
          initial={{ scale: 0.9 }}
			    whileInView={{ scale: 1 }}
          transition={{ duration: 1, type: "spring", stiffness: 100 }}
          className="absolute top-0 left-0 w-full h-full hidden group-hover:flex flex-col items-center justify-center p-6">
          <div className="bg-troo-primaryDark bg-opacity-70 w-full h-full self-center p-8 flex flex-col items-center justify-center">
            <div className="flex flex-col items-start justify-center gap-4">
              <div className="flex items-center gap-3 group">
                <div className="text-sm rounded-lg text-troo-primaryDark bg-troo-light w-8 h-8 flex items-center justify-center">
                  <FaFacebookF/>
                </div>
                <a href='#' className="text-troo-light">Facebook</a>
              </div>
              <div className="flex items-center gap-3 group">
                <div className="text-sm rounded-lg text-troo-primaryDark bg-troo-light w-8 h-8 flex items-center justify-center">
                  <FaInstagram/>
                </div>
                <a href='#' className="text-troo-light">Instagram</a>
              </div>
              <div className="flex items-center gap-3 group">
                <div className="text-sm rounded-lg text-troo-primaryDark bg-troo-light  w-8 h-8 flex items-center justify-center">
                  <FaTwitter/>
                </div>
                <a href='#' className="text-troo-light">Twitter</a>
              </div>
              <div className="flex items-center gap-3 group">
                <div className="text-sm rounded-lg text-troo-primaryDark bg-troo-light  w-8 h-8 flex items-center justify-center">
                  <FaLinkedinIn/>
                </div>
                <a href='#' className="text-troo-light">LinkedIn</a>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
        <div className="w-full bg-troo-light p-6 flex flex-col items-center text-center justify-center">
          <Link to={ROUTES.teamDetails}>
            <h4 className="text-troo-primaryDark">{title}</h4>
          </Link>
          <p className="text-troo-primaryDark">{designation}</p>
        </div>
    </motion.div>
  )
}

export default TeamCard