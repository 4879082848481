import React from "react";
import Layout from "../shared/components/Layout";
import HeroSection from "../shared/sections/HeroSection";
import ServiceSection from "../shared/sections/ServiceSection";
import AboutSection from "../shared/sections/AboutSection";
import OfferSection from "../shared/sections/OfferSection";
import GalarySection from "../shared/sections/GalarySection";
import TeamSection from "../shared/sections/TeamSection";
import ContactBanner from "../shared/sections/ContactBanner";
import ClientReviewSection from "../shared/sections/ClientReviewSection";
import PricingSection from "../shared/sections/PricingSection";
import BlogSection from "../shared/sections/BlogSection";
import ClientBrands from "../shared/sections/ClientBrands";

const Home = () => {
	return (
		<Layout>
			<HeroSection />
			<div className="w-full bg-troo-gray h-96"></div>
			<ServiceSection />
			<AboutSection />
			<OfferSection />
			<GalarySection />
			<TeamSection />
			<ContactBanner/>
			<ClientReviewSection/>
			<PricingSection/>
			<BlogSection />
			<ClientBrands/>
		</Layout>
	);
};

export default Home;
