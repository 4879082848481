import React from 'react'
import { FiPhoneCall } from 'react-icons/fi';
import { HiOutlineClock, HiOutlineLocationMarker, HiOutlineMailOpen } from 'react-icons/hi';

const ContactForm = () => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full pt-[100px] bg-transparent text-troo-primaryDark">
        <div className="w-full grid grid-cols-1 xl:grid-cols-3 gap-6 bg-troo-gray p-6 lg:p-14">
          <div className="w-full xl:col-span-2 flex flex-col gap-5 items-start">
            <div className="w-full grid grid-cols-2 gap-4">
              <div className="w-full flex flex-col items-start gap-3">
                <p>Full Name</p>
                <input
                  type="text"
                  className="troo-input bg-troo-light w-full"
                  placeholder="Full Name"
                />
              </div>
              <div className="w-full flex flex-col items-start gap-3">
                <p>Phone No</p>
                <input
                  type="text"
                  className="troo-input bg-troo-light w-full"
                  placeholder="+44 123 465 7890"
                />
              </div>
              <div className="w-full flex flex-col items-start gap-3">
                <p>Your Email</p>
                <input
                  type="text"
                  className="troo-input bg-troo-light w-full"
                  placeholder="contact@troothemes.com"
                />
              </div>
              <div className="w-full flex flex-col items-start gap-3">
                <p>Select Subject</p>
                <select
                  className="troo-input bg-troo-light w-full">
                  <option>Select</option>
                  <option>Query</option>
                  <option>Advertisement</option>
                  </select>
              </div>
              <div className="w-full flex col-span-2 flex-col items-start gap-3">
                <p>Write Comment</p>
                <textarea
                  rows={7}
                  className="troo-input bg-troo-light w-full"
                  placeholder="Write Comment"
                />
              </div>
            </div>
            <button className='btn-filled-dark'>Post Comment</button>
          </div>
          <div className='w-full flex flex-col items-start bg-troo-primaryDark p-8 gap-8'>
            <div className="flex flex-col gap-3">
              <p className='text-troo-light font-bold text-xl'>Contact Information</p>
              <div className='w-8 h-1 bg-troo-secondary'/>
            </div>
            <div className='flex flex-col items-start gap-8'>
              <div className='flex items-center gap-3'>
                <FiPhoneCall className='text-troo-secondary text-2xl' />
                <div className='flex flex-col items-start gap-1 text-troo-light'>
                  <p className='text-sm'>Call Us on</p>
                  <p className='text-xl font-bold'>+44 123 456 7890</p>
                </div>
              </div>
              <div className='flex items-center gap-3'>
                <HiOutlineMailOpen className='text-troo-secondary text-2xl' />
                <div className='flex flex-col items-start gap-1 text-troo-light'>
                  <p className='text-sm'>Email Us on</p>
                  <p className='text-xl font-bold'>example@email.com</p>
                </div>
              </div>
              <div className='flex items-center gap-3'>
                <HiOutlineLocationMarker className='text-troo-secondary text-2xl' />
                <div className='flex flex-col items-start gap-1 text-troo-light'>
                  <p className='text-sm'>Address</p>
                  <p className='text-xl font-bold'>4516 School Street <br/>Danbury, CT, Canada</p>
                </div>
              </div>
              <div className='flex items-center gap-3'>
                <HiOutlineClock className='text-troo-secondary text-2xl' />
                <div className='flex flex-col items-start gap-1 text-troo-light'>
                  <p className='text-sm'>Opening Hours</p>
                  <p className='text-xl font-bold'>Mon-Fri: 10am to 06pm <br/>Sat-sun: Closed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.001524964496!2d72.78482827479901!3d21.1920983804984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04d198e284e31%3A0x56c3bab7f1aff804!2sTRooTech%20Business%20Solutions%20Pvt.%20Ltd.%20-%20Surat!5e0!3m2!1sen!2sin!4v1695212427977!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="TrooTech"></iframe>
      </div>
    </div>
  )
}

export default ContactForm