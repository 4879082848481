import React from 'react'
import TeamMember from "../../assets/images/team-member.png"
import { FaFacebookF } from 'react-icons/fa'
import { BsCheckCircleFill, BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'

const TeamDetailSection = () => {
  return (
    <div className="w-full bg-troo-gray">
      <div className="main-container w-full py-[100px] flex flex-col items-center gap-12">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          <img src={TeamMember} alt="Team Member" className="w-full h-full object-cover" />
          <div className="w-full lg:col-span-2">
            <div className="w-full flex flex-col items-start gap-3">
              <div className="w-full flex items-start justify-between gap-4">
                <div className="flex flex-col items-start gap-2">
                  <h5 className="text-troo-primaryDark font-bold text-xl">Jennifer Johnson</h5>
                  <p className="text-sm font-light text-troo-primaryLight">CEO and Founder</p>
                </div>
                <div className="flex items-center gap-3">
                  <p className="text-base text-troo-primaryDark">Social Media:</p>
                  <div className="flex items-center gap-3">
                    <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                      <FaFacebookF />
                    </div>
                    <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                      <BsTwitter />
                    </div>
                    <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                      <BsInstagram />
                    </div>
                    <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                      <BsYoutube />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-2 flex-wrap text-troo-primaryDark ">
                <p>Phone No: <span className="font-bold"> +44 123 456 7890</span></p>
                <p>Email: <span className="font-bold">jennijohn@troothemes.com</span></p>
              </div>
              <p className='text-troo-primaryLight text-sm font-light'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus, ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem nunc. Ut sit amet velit sed sem sodales cursus quis at nibh. ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam.
              </p>
              <p className='text-troo-primaryLight text-sm font-light'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem nunc. Ut sit amet velit cursus quis at nibh. 
              </p>
              <div className='grid grid-cols-1 lg:grid-cols-2 w-full gap-4'>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Every lorem ipsum sit amet just dummy.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Lorem ipsum dollr sit amet just dummy.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Every lorem ipsum sit amet just dummy.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TeamDetailSection