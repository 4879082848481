import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ClientBrands from '../shared/sections/ClientBrands'
import BlogDetailSection from '../shared/sections/BlogDetailSection'

const BlogDetails = () => {
  return (
    <Layout>
      <PageHeaderSection title="Read From Our Blog" />
      <BlogDetailSection />
      <ClientBrands />
		</Layout>
  )
}

export default BlogDetails