import React from 'react'
import Heading from '../components/Heading'
import Img1 from "../../assets/images/team/image.png"
import Img2 from "../../assets/images/team/image-1.png"
import Img3 from "../../assets/images/team/image-2.png"
import TeamCard from '../components/TeamCard'

const TeamSection = ({ isListingPage }) => {
  return (
    <div className="w-full bg-troo-gray">
      <div className="main-container w-full py-[100px] flex flex-col items-center gap-12">
        {!isListingPage && (
          <div className="w-full flex flex-col lg:flex-row items-center justify-between gap-4">
            <Heading headline="Meet our team member" heading={ 
              <>
                Meet the team members <br/> behind our success!
              </>
            } />
            <div className="flex items-center gap-4">
              <p className="text-sm font-light">
                Lorem Ipsum is simply dummy text of tiaot you he Lorem Ipsum is simply dummy text theprinting and typesetting industryprinting and typesetting industry Lorem Ipsum is simply dummy.
              </p>
              <button className="btn-filled whitespace-nowrap">View All Member</button>
            </div>
          </div>
        )}
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <TeamCard img={Img1} designation="CEO and Founder" title="John Ferguson" />
          <TeamCard img={Img2} designation="CEO and Founder" title="Jennifer Johnson" />
          <TeamCard img={Img3} designation="CEO and Founder" title="Michael Hensley" />
          {
            isListingPage && (
              <>
                <TeamCard img={Img1} designation="CEO and Founder" title="John Ferguson" />
                <TeamCard img={Img2} designation="CEO and Founder" title="Jennifer Johnson" />
                <TeamCard img={Img3} designation="CEO and Founder" title="Michael Hensley" />
                <TeamCard img={Img1} designation="CEO and Founder" title="John Ferguson" />
                <TeamCard img={Img2} designation="CEO and Founder" title="Jennifer Johnson" />
                <TeamCard img={Img3} designation="CEO and Founder" title="Michael Hensley" />
              </>
            )
          }
        </div>
        {
          !isListingPage && (
            <div className="flex flex-col items-center justify-center gap-2">
              <p className="text-troo-primaryLight text-2xl font-light">Want to join with us?</p>
              <p className="text-troo-primaryLight text-3xl font-bold">Contact With Us</p>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default TeamSection