import React from 'react'
import Heading from '../components/Heading'
import ReviewCard from '../components/ReviewCard'
import { ROUTES } from '../constants/routes'
import { Link } from 'react-router-dom'

const ClientReviewSection = ({isListingPage}) => {
  return (
    <div className="w-full bg-troo-light bg-quote bg-center bg-no-repeat">
      <div className="main-container w-full py-[100px] flex flex-col items-center gap-12">
        {
          !isListingPage && (
            <Heading align="center" headline="What Client Says?" heading={ 
              <>
                Explore our very satisfied <br/> clients testimonials
              </>
            } />
          )
        }
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <ReviewCard/>
          <ReviewCard/>
          <ReviewCard />
          {
            isListingPage && (
              <>
                <ReviewCard/>
                <ReviewCard/>
                <ReviewCard />
                <ReviewCard/>
                <ReviewCard/>
                <ReviewCard />
              </>
            )
          }
        </div>
        {
          !isListingPage && (
            <Link to={ROUTES.testimonial}>
              <button className="btn-filled font-light">View All Reviews</button>
            </Link>
          )
        }
      </div>
    </div>
  )
}

export default ClientReviewSection