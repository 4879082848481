import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'

const PageHeaderSection = ({ title }) => {
  return (
    <div className="py-8 w-full h-[330px] bg-hero-back bg-center bg-no-repeat">
			<div className="relative main-container-big flex h-full items-center justify-start">
				<div className="flex flex-col items-start text-troo-light gap-8">
          <div className="px-4 py-3 bg-troo-secondary bg-opacity-5 border-l-2 border-troo-secondary">
					  <Breadcrumbs />
          </div>
          <h1>{title}</h1>
				</div>
			</div>
		</div>
  )
}

export default PageHeaderSection