import React from 'react'
import { IoChatbubblesOutline } from 'react-icons/io5'

const ContactBanner = () => {
  return (
    <div className="w-full grid md:grid-cols-2">
      <div className="flex flex-col gap-3 items-start bg-troo-secondary p-10 lg:p-14 bg-contact-bg-1">
        <p className="text-troo-primaryDark">Want to Consult With Us?</p>
        <h3 className="text-troo-primaryDark">Feel free to call us any time for<br/> help or make an appionment</h3>
      </div>
      <div className="bg-troo-primaryDark w-full h-full p-10 lg:p-14 flex items-center justify-center bg-contact-bg-2 bg-center bg-contain">
        <div className="w-full h-full flex xl:flex-row flex-col gap-4 items-start xl:items-center justify-between">
          <div className="flex items-center gap-3">
            <div className='bg-troo-secondary p-3 rounded-full text-troo-primaryDark text-2xl'>
              <IoChatbubblesOutline/>
            </div>
            <div className='flex flex-col items-start text-troo-light'>
              <p>Call Us Today</p>
              <h3 className='text-2xl'>+44 123 456 7890</h3>
            </div>
          </div>
          <div className='w-10 h-10 border border-troo-light border-opacity-20 flex items-center justify-center rounded-full'>OR</div>
          <button className='btn-filled'>Contact with us</button>
        </div>
      </div>
    </div>
  )
}

export default ContactBanner