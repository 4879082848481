import React from 'react'
import { motion } from "framer-motion";

const ServiceCard = ({ no, serviceName, serviceDesc, img }) => {
  return (
    <motion.div
      initial={{ y: 60, scale: 0.9 }}
			whileInView={{ y: 1, scale: 1 }}
      transition={{ duration: 1, type: "spring", stiffness: 100 }}
      className='relative flex items-center border border-troo-secondary bg-white py-6 md:py-10 px-10 md:px-14 rounded-xl md:rounded-full gap-4 md:gap-9'>
      <div className='absolute -left-6 text-troo-secondary bg-troo-primaryDark font-bold text-lg md:text-2xl w-12 h-12 md:w-16 md:h-16 rounded-full text-center flex items-center justify-center flex-grow'>{no}</div>
      <div className='flex flex-col items-start gap-2'>
        <h4 className='text-troo-primaryDark font-semibold text-xl md:text-2xl'>{serviceName}</h4>
        <p className='font-light text-sm md:text-base'>{serviceDesc}</p>
      </div>
      <div className="w-28 h-28 hidden sm:flex items-center justify-center">
        <img src={img} alt="pencil"/>
      </div>
    </motion.div>
  )
}

export default ServiceCard