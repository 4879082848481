import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ClientBrands from '../shared/sections/ClientBrands'
import { WorkDetailSection } from '../shared/sections/WorkDetailSection'
import ClientReviewSection from '../shared/sections/ClientReviewSection'

const WorkDetails = () => {
  return (
    <Layout>
      <PageHeaderSection title="Digital Printing" />
      <WorkDetailSection />
      <ClientReviewSection/>
      <ClientBrands />
		</Layout>
  )
}

export default WorkDetails