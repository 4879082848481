import React from 'react'
import Layout from '../shared/components/Layout'
import AboutSection from '../shared/sections/AboutSection'
import OfferSection from '../shared/sections/OfferSection'
import TeamSection from '../shared/sections/TeamSection'
import ContactBanner from '../shared/sections/ContactBanner'
import ClientReviewSection from '../shared/sections/ClientReviewSection'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ClientBrands from '../shared/sections/ClientBrands'

const About = () => {
  return (
    <Layout>
      <PageHeaderSection title="Know About Us"/>
			<AboutSection />
			<OfferSection />
			<TeamSection />
			<ContactBanner/>
			<ClientReviewSection />
			<ClientBrands/>
		</Layout>
  )
}

export default About