import React from 'react'
import TeamImg from "../../assets/images/blog-details.png"
import WidgetImg from "../../assets/images/widget-img.png"
import info1 from "../../assets/images/Info.png"
import pr1 from "../../assets/images/projects/img.png"
import pr2 from "../../assets/images/projects/img-1.png"
import pr3 from "../../assets/images/projects/img-2.png"
import pr4 from "../../assets/images/projects/img-3.png"
import pr5 from "../../assets/images/projects/img-4.png"
import info2 from "../../assets/images/Info-1.png"
import { BsCheckCircleFill, BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import ContactForm from './ContactForm'

const BlogDetailSection = () => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full pt-[100px]">
        <div className="grid grid-cols-1 lg:grid-cols-3 md:gap-6 items-start">
          <div className="col-span-2 flex flex-col items-start gap-4">
            <div className="w-full aspect-video overflow-hidden rounded-lg">
              <img src={TeamImg} alt="service img" className="object-cover object-center w-full h-full"/>
            </div>
            <h2 className="text-troo-primaryDark text-4xl">The best 5 tips for choosing the right color sticker</h2>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem
              nunc. Ut sit amet velit sed sem sodales cursus quis at nibh. ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem</p>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem
              nunc. Ut sit amet velit cursus quis at nibh. </p>
            <h2 className="text-troo-primaryDark text-2xl">Print & Procurement</h2>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem
              nunc. Ut sit amet velit cursus quis at nibh. </p>
            <div className="grid grid-cols-2 w-full gap-3">
              <img src={info1} className="w-full h-full object-cover aspect-square object-center" alt="img" />
              <img src={info2} className="w-full h-full object-cover aspect-square object-center" alt="img" />
            </div>
            <h2 className="text-troo-primaryDark text-2xl">Print & Procurement</h2>
            <div className='grid grid-cols-1 lg:grid-cols-2 w-full gap-4'>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Every lorem ipsum sit amet just dummy.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Lorem ipsum dollr sit amet just dummy.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Every lorem ipsum sit amet just dummy.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
                  <div className="flex items-center gap-2">
                    <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                    <p className='text-troo-primaryDark text-sm md:text-base'>Using powerful lorem ipsum dollr sit amet.</p>
                  </div>
              </div>
            <h2 className="text-troo-primaryDark text-2xl">Let’s see project Photos</h2>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
            ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu.</p>
            <div className="flex items-center gap-3 py-5 border-y border-troo-primaryDark w-full">
              <p className="text-base text-troo-primaryDark">Share This Article:</p>
              <div className="flex items-center gap-3">
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <FaFacebookF />
                </div>
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <BsTwitter />
                </div>
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <BsInstagram />
                </div>
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <BsYoutube />
                </div>
              </div>
            </div>
            <div className="flex w-full text-troo-primaryDark flex-col items-start gap-6">
              <h4>Comments (0)</h4>
              <h6>Leave A Comment</h6>
              <div className="w-full gap-6 bg-troo-light">
                <div className="w-full flex flex-col gap-5 items-start">
                  <div className="w-full grid grid-cols-2 gap-4">
                    <div className="w-full flex flex-col items-start gap-3">
                      <p>Full Name</p>
                      <input
                        type="text"
                        className="troo-input bg-troo-light w-full"
                        placeholder="Full Name"
                      />
                    </div>
                    <div className="w-full flex flex-col items-start gap-3">
                      <p>Phone No</p>
                      <input
                        type="text"
                        className="troo-input bg-troo-light w-full"
                        placeholder="+44 123 465 7890"
                      />
                    </div>
                    <div className="w-full flex flex-col items-start gap-3">
                      <p>Your Email</p>
                      <input
                        type="text"
                        className="troo-input bg-troo-light w-full"
                        placeholder="contact@troothemes.com"
                      />
                    </div>
                    <div className="w-full flex flex-col items-start gap-3">
                      <p>Your Phone</p>
                      <input
                        type="text"
                        className="troo-input bg-troo-light w-full"
                        placeholder="Enter Your Phone"
                      />
                    </div>
                    <div className="w-full flex col-span-2 flex-col items-start gap-3">
                      <p>Write Comment</p>
                      <textarea
                        rows={7}
                        className="troo-input bg-troo-light w-full"
                        placeholder="Write Comment"
                      />
                    </div>
                  </div>
                  <button className='btn-filled-dark'>Post Comment</button>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-8 lg:mt-0 w-full md:grid-cols-2 lg:flex lg:flex-col items-start gap-5">
            <div className="w-full h-auto p-7 text-troo-primaryDark  bg-troo-gray flex flex-col gap-4 items-start rounded-xl">
              <h6 className='text-2xl'>Blog Categories</h6>
              <div className="h-[1px] w-full bg-troo-primaryDark bg-opacity-10 flex flex-col items-start gap-4">
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-4 justify-between">
                <span>Printing Service</span>
                <span>10</span>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-4 justify-between">
                <span>3D Print</span>
                <span>09</span>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-4 justify-between">
                <span>Graphics Design</span>
                <span>08</span>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-4 justify-between">
                <span>Bilboard Print</span>
                <span>07</span>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-4 justify-between">
                <span>Wall Logo Design Print</span>
                <span>06</span>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-4 justify-between">
                <span>Sticker Print</span>
                <span>05</span>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-4 justify-between">
                <span>Banner Print</span>
                <span>04</span>
              </div>
            </div>
            <div className="w-full bg-troo-gray flex flex-col items-start p-7 rounded-xl gap-4">
              <h6 className='text-2xl text-troo-primaryDark'>Blog Tags</h6>
              <div className="h-[1px] w-full bg-troo-primaryDark bg-opacity-10 flex flex-col items-start gap-4">
              </div>
              <div className='w-full flex flex-wrap items-center gap-3'>
                <p className='px-4 py-3 bg-troo-light text-troo-primaryDark rounded-lg'>Printing</p>
                <p className='px-4 py-3 bg-troo-light text-troo-primaryDark rounded-lg'>Printing Agency</p>
                <p className='px-4 py-3 bg-troo-light text-troo-primaryDark rounded-lg'>3D Print</p>
                <p className='px-4 py-3 bg-troo-light text-troo-primaryDark rounded-lg'>Banner Print</p>
                <p className='px-4 py-3 bg-troo-light text-troo-primaryDark rounded-lg'>Bilboard Print</p>
                <p className='px-4 py-3 bg-troo-light text-troo-primaryDark rounded-lg'>T-shirt Print</p>
              </div>
            </div>
            <div className="w-full h-auto p-7 text-troo-primaryDark  bg-troo-gray flex flex-col gap-4 items-start rounded-xl">
              <h6 className='text-2xl'>Related Blogs</h6>
              <div className="h-[1px] w-full bg-troo-primaryDark bg-opacity-10 flex flex-col items-start gap-4">
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr1} alt="project 1" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr2} alt="project 2" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr3} alt="project 3" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr4} alt="project 4" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr5} alt="project 5" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              
            </div>
            <div className="w-full relative flex flex-col items-center rounded-lg text-center bg-troo-primaryDark">
              <div className="flex absolute top-0 flex-col items-center p-8 gap-6">
                <h2 className="text-4xl text-troo-light font-bold">Ready to Work Togather</h2>
                <button className="btn-filled">Get A Quote</button>
              </div>
              <img src={WidgetImg} className='w-full h-full' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogDetailSection