import React from 'react'
import Logo from "../assets/images/logo-light.svg"
import { FaFacebookF } from 'react-icons/fa'
import { BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'

const ComingSoon = () => {
  return (
    <div className="w-full min-h-screen bg-troo-gray bg-come-soon bg-no-repeat bg-center">
      <div className="main-container text-center w-full h-full p-12 flex flex-col items-center gap-16 justify-center">
        <div className="w-full flex flex-col md:flex-row items-center gap-4 justify-between">
          <img src={Logo} alt="logo" />
          <div className="flex items-center gap-3">
						<div className="flex items-center justify-center p-3 text-troo-primaryDark border-2 border-troo-primaryDark border-opacity-20 rounded-full text-sm lg:text-lg">
							<FaFacebookF />
						</div>
						<div className="flex items-center justify-center p-3 text-troo-primaryDark border-2 border-troo-primaryDark border-opacity-20 rounded-full text-sm lg:text-lg">
							<BsTwitter />
						</div>
						<div className="flex items-center justify-center p-3 text-troo-primaryDark border-2 border-troo-primaryDark border-opacity-20 rounded-full text-sm lg:text-lg">
							<BsInstagram />
						</div>
						<div className="flex items-center justify-center p-3 text-troo-primaryDark border-2 border-troo-primaryDark border-opacity-20 rounded-full text-sm lg:text-lg">
							<BsYoutube />
						</div>
					</div>
        </div>
        <div className="w-full flex flex-col items-center gap-6 justify-center text-center">
          <div className='text-sm text-troo-light px-3 py-2 bg-troo-primaryDark rounded-lg'>Coming Soon</div>
          <h2 className='text-troo-primaryDark text-4xl'>Our new website is on its way.</h2>
          <p className='text-troo-tertiary'>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in <br /> a piece of classical Latin literature from 45 BC Lorem Ipsum.</p>
          <div className='flex flex-col items-start w-full md:w-1/2 gap-3'>
            <p className='text-troo-primaryDark'>Notify Me</p>
            <div className='flex w-full items-center'> 
              <input
                type="text"
                className="troo-input bg-transparent w-full"
                placeholder="Enter your Email"
              />
              <button className='bg-troo-primaryDark text-troo-light h-full py-3 px-8'>Submit</button>
            </div>
          </div>
          <div className='text-troo-primaryDark text-xl flex items-center gap-3 flex-wrap text-center'>
            Contact Us:
            <span className='font-bold'>+44 987 654 3210</span>
            <span className='font-bold'>contact@troothems.com</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComingSoon