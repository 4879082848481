import React from "react";
import { BsPlayFill } from "react-icons/bs";
import { motion } from "framer-motion";

const HeroSection = () => {
	return (
		<div className="w-full bg-troo-primaryDark bg-hero-back bg-no-repeat bg-center">
			<div className="main-container w-full pt-[100px] md:pt-[100px] pb-[100px] flex flex-col items-center gap-6">
				<div className="flex flex-col lg:flex-row items-center justify-between gap-12">
					<motion.div initial={{ y: 40 }}
					whileInView={{ y: 1 }}
					transition={{ duration: 1, type: "spring", stiffness: 100 }} className="text-troo-light w-full xl:w-auto text-4xl md:text-5xl 2xl:text-7xl flex flex-col items-start gap-3">
						<span className="font-light">We Are Digital</span>
						<span className="font-bold">Print Media Agency</span>
					</motion.div>
					<motion.div initial={{ y: 40 }}
					whileInView={{ y: 1 }}
					transition={{ duration: 1, type: "spring", stiffness: 100 }} className="flex w-full xl:w-[40%] pl-8 border-l-4 border-troo-secondary flex-col items-start text-troo-light gap-3 text-lg">
						<p>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. We are Ut
							elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
						</p>
						<div className="flex flex-wrap items-center gap-4">
							<button className="btn-filled">
								Know More
							</button>
							<button className="btn-outlined">
								View Our Services
							</button>
						</div>
					</motion.div>
				</div>
				<motion.div initial={{ scale: 0.7 }}
					whileInView={{ scale: 1 }}
					transition={{ duration: 1, type: "spring", stiffness: 100 }} className="relative mt-10 -mb-[80%] sm:-mb-[40%] lg:-mb-[30%] bg-no-repeat w-full lg:w-[80%] h-[400px] lg:h-[550px] bg-cover bg-troo-light bg-home-video-banner aspect-video bg-top bg-fixed">
					<div className="absolute top-0 left-0 w-full h-full bg-troo-primaryDark bg-opacity-50 flex items-center justify-center shadow-lg" onClick={() => window.my_modal_2.showModal()}>
						<div className="relative flex items-center justify-center bg-troo-secondary text-troo-primaryDark rounded-full p-5 text-4xl z-[1]">
							<div className="absolute w-full h-full bg-troo-secondary rounded-full bg-opacity-40 scale-125 -z-[1] animate-pulse animate-infinite duration-100 delay-75"></div>
							<div className="absolute w-full h-full bg-troo-secondary rounded-full bg-opacity-30 scale-150 -z-[1] animate-pulse animate-infinite duration-300 delay-200"></div>
							<div className="absolute w-full h-full bg-troo-secondary rounded-full bg-opacity-20 scale-[1.75] -z-[1] animate-pulse animate-infinite duration-700 delay-500"></div>
							<BsPlayFill />
						</div>
					</div>
				</motion.div>
			</div>
			<dialog id="my_modal_2" className="modal">
				<form
					method="dialog"
					className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
				>
					<iframe
						width="100%"
						height="500"
						src="https://www.youtube.com/embed/DUfXdcpEfMs"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
						allowfullscreen
					></iframe>
				</form>
				<form method="dialog" className="modal-backdrop">
					<button>close</button>
				</form>
			</dialog>
		</div>
	);
};

export default HeroSection;
