import React from 'react'
import Heading from '../components/Heading'
import BlogCard from '../components/BlogCard'
import img1 from "../../assets/images/blogs/b1.jpg"
import img2 from "../../assets/images/blogs/b2.png"
import img3 from "../../assets/images/blogs/b3.jpg"
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

const BlogSection = ({isListingPage}) => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full py-[100px] flex flex-col items-center gap-12">
        {
          !isListingPage && (
            <Heading align="center" headline="What Client Says?" heading={ 
              <>
                Latest news & article from <br/>TRoo print blogs
              </>
            } />
          )
        }
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <BlogCard img={img1}/>
          <BlogCard img={img2}/>
          <BlogCard img={img3} />
          {
            isListingPage && (
              <>
                <BlogCard img={img1}/>
                <BlogCard img={img2}/>
                <BlogCard img={img3} />
                <BlogCard img={img1}/>
                <BlogCard img={img2}/>
                <BlogCard img={img3} />
              </>
            )
          }
        </div>
        {
          !isListingPage && (
            <Link to={ROUTES.blogsGrid}>
              <button className="btn-outlined-dark whitespace-nowrap">View All Blogs</button>
            </Link>
          )
        }
      </div>
    </div>
  )
}

export default BlogSection