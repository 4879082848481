import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ClientBrands from '../shared/sections/ClientBrands'
import { WorkDetailSection } from '../shared/sections/WorkDetailSection'

const ServiceDetails = () => {
  return (
    <Layout>
      <PageHeaderSection title="Digital Printing" />
      <WorkDetailSection/>
      <ClientBrands />
		</Layout>
  )
}

export default ServiceDetails