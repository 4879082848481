import React from 'react'
import { BsArrowRight } from 'react-icons/bs'
import { motion } from "framer-motion";
import { ROUTES } from '../constants/routes';
import { Link } from 'react-router-dom';

const BlogCard = ({img, category, title}) => {
  return (
    <motion.div
      initial={{ y: 60, scale: 0.9 }}
			whileInView={{ y: 1, scale: 1 }}
      transition={{ duration: 1, type: "spring", stiffness: 100 }}
      className="w-full h-[354px] relative group overflow-hidden"
    >
      <img src={img} alt="img1" className='w-full h-full object-cover object-center' />
      <div className="absolute top-0 left-0 w-full h-full flex flex-col items-start justify-between p-6">
        <div className="absolute top-0 left-0 bg-troo-gray w-full h-full flex group-hover:hidden flex-col items-start justify-end p-6"></div>
        <motion.div
          initial={{ scale: 0.9 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 1, type: "spring", stiffness: 100 }}
          className="absolute top-0 left-0 bg-troo-primaryDark bg-opacity-50 w-full h-full hidden group-hover:flex flex-col items-start justify-end p-6"></motion.div>
        <div className="p-4 bg-troo-secondary text-center z-10 text-troo-primaryDark font-semibold">25, Feb <br /> 2023</div>
          <div className="flex text-troo-primaryDark flex-col items-start gap-3 group-hover:text-troo-light z-10">
        
            <div className="bg-troo-primaryDark bg-opacity-5 px-3 py-2 rounded-xl">Investment</div>
            <p className="text-xl font-bold">Print buyers should focus on growing return on investment</p>
        <Link to={ROUTES.blogDetails}>
            <div className="flex items-center group-hover:text-troo-light font-light text-troo-primaryDark gap-3">
              <span>READ MORE</span>
              <BsArrowRight/>
            </div>
        </Link>
          </div>
      </div>
    </motion.div>
  )
}

export default BlogCard