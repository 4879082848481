import React from 'react'
import img1 from "../../assets/images/clients-brand/logo_01.png"
import img2 from "../../assets/images/clients-brand/logo_02.png"
import img3 from "../../assets/images/clients-brand/logo_03.png"
import img4 from "../../assets/images/clients-brand/logo_04.png"
import img5 from "../../assets/images/clients-brand/logo_05.png"
import img6 from "../../assets/images/clients-brand/logo_06.png"

const ClientBrands = () => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full py-[50px] grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-6 gap-5 items-center justify-center">
        <img src={img1} alt="img" className="w-full h-full"/>
        <img src={img2} alt="img" className="w-full h-full"/>
        <img src={img3} alt="img" className="w-full h-full"/>
        <img src={img4} alt="img" className="w-full h-full"/>
        <img src={img5} alt="img" className="w-full h-full"/>
        <img src={img6} alt="img" className="w-full h-full"/>
      </div>
    </div>
  )
}

export default ClientBrands