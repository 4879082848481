import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ClientBrands from '../shared/sections/ClientBrands'
import TeamDetailSection from '../shared/sections/TeamDetailSection'

const TeamDetails = () => {
  return (
    <Layout>
      <PageHeaderSection title="Meet Our Owesome Team" />
      <TeamDetailSection />
      <ClientBrands />
		</Layout>
  )
}

export default TeamDetails