import React from 'react'
import { BsCheckCircleFill } from 'react-icons/bs'

const CheckText = ({title, isDisabled}) => {
  return (
    <div className={`flex items-center gap-6 text-troo-secondary ${isDisabled && "opacity-25"}`}>
      <div>
        <BsCheckCircleFill className="text-xl bg-troo-primaryDark rounded-full"/>
      </div>
      <p className='text-troo-primaryDark'>{title}</p>
    </div>
  )
}

export default CheckText