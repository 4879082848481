import React from 'react'
import { motion } from "framer-motion";

const Heading = ({ headline, heading, align, isDark }) => {
  return (
    <motion.div initial={{ x: 100 }}
			whileInView={{ x: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className={`w-full flex flex-col gap-4 ${align === 'center' ? "text-center items-center" : "items-start"}`}>
      <p className={` bg-opacity-20 px-3 py-2 rounded-full ${isDark ? "text-troo-light bg-troo-secondary bg-opacity-20" : "text-troo-primaryLight bg-troo-primaryLight"}`}>{headline}</p>
      <h4 className={`text-3xl lg:text-5xl font-bold ${isDark ? "text-troo-light" : "text-troo-primaryDark"}`}>{heading}</h4>
    </motion.div>
  )
}

export default Heading