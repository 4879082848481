import React from 'react'
import Heading from '../components/Heading'
import ServiceCard from '../components/ServiceCard'
import pencil from "../../assets/images/pencil.png"
import layers from "../../assets/images/layers.png"
import print from "../../assets/images/print.png"
import paint from "../../assets/images/paint.png"
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

const ServiceSection = ({isListingPage}) => {
  return (
    <div className="w-full bg-troo-gray">
      <div className={`main-container w-full ${isListingPage ? "py-[100px]" : "pb-[100px]"} flex flex-col items-center gap-12`}>
        {!isListingPage && (
          <Heading align="center" headline="What we do" heading={ 
            <>
              The services we <br />
              provide with perfection
            </>
          } />
        )}
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-y-4 gap-x-14 pl-8">
          <ServiceCard img={pencil} no="01" serviceName="Printing Services" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis."/>
          <ServiceCard img={layers} no="02" serviceName="Copying Services" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis."/>
          <ServiceCard img={print} no="03" serviceName="Outdoor Printing" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis."/>
          <ServiceCard img={paint} no="04" serviceName="Clothes Printing" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis." />
          {
            isListingPage && (
              <>
                <ServiceCard img={print} no="05" serviceName="Digital Scaning" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis."/>
                <ServiceCard img={paint} no="06" serviceName="Promotional Printing" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis." />
                <ServiceCard img={print} no="07" serviceName="Graphic Design" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis."/>
                <ServiceCard img={paint} no="08" serviceName="Offset Printing" serviceDesc="Lorem ipsum dolor sit amet, consectetur adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis." />
              </>
            )
          }
        </div>
        <Link to={ROUTES.ourServices}>
          <button className="btn-filled font-light shadow-m">View All Services</button>
        </Link>
      </div>
    </div>
  )
}

export default ServiceSection