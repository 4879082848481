import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ContactForm from '../shared/sections/ContactForm'
import ClientBrands from '../shared/sections/ClientBrands'

const Contact = () => {
  return (
    <Layout>
      <PageHeaderSection title="Get in Touch With Us" />
      <ContactForm />
      <ClientBrands/>
    </Layout>
  )
}

export default Contact