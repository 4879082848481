import React from 'react'
import { motion } from "framer-motion";
import { ROUTES } from '../constants/routes';
import { Link } from 'react-router-dom';

const GalaryCard = ({img, category, title}) => {
  return (
    <motion.div initial={{ scale: 0.7 }}
			whileInView={{ scale: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className="w-full h-[354px] relative group overflow-hidden">
      <img src={img} alt="img1" className='w-full h-full object-cover object-center' />
      <motion.div initial={{ scale: 0.7 }}
			whileInView={{ scale: 1 }}
        transition={{ duration: 1, type: "spring", stiffness: 100 }} className="absolute top-0 left-0 bg-troo-primaryDark bg-opacity-50 w-full h-full hidden group-hover:flex flex-col items-start justify-between p-6">
        <Link to={ROUTES.portfolioDetails}>
          <div className="relative flex items-center justify-center bg-troo-secondary text-troo-primaryDark rounded-full w-8 h-8 text-xl z-[1]">
            <div className="absolute w-full h-full bg-troo-secondary rounded-full bg-opacity-40 scale-125 -z-[1] animate-pulse animate-infinite duration-100 delay-75"></div>
            +
          </div>
        </Link>
        <div className="flex flex-col items-start gap-2">
          <p className="text-troo-light">{category}</p>
          <h4 className="text-troo-light text-3xl">{title}</h4>
        </div>
      </motion.div>
    </motion.div>
  )
}

export default GalaryCard