import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import TeamSection from '../shared/sections/TeamSection'
import ClientBrands from '../shared/sections/ClientBrands'

const OurTeam = () => {
  return (
    <Layout>
			<PageHeaderSection title="Meet Our Owesome Team"/>
      <TeamSection isListingPage />
      <ClientBrands/>
		</Layout>
  )
}

export default OurTeam