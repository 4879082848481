import React from 'react'
import TeamImg from "../../assets/images/team-detail-img.png"
import ServiceImg2 from "../../assets/images/work-detail-2.png"
import WidgetImg from "../../assets/images/widget-img.png"
import info1 from "../../assets/images/Info.png"
import pr1 from "../../assets/images/projects/img.png"
import pr2 from "../../assets/images/projects/img-1.png"
import pr3 from "../../assets/images/projects/img-2.png"
import pr4 from "../../assets/images/projects/img-3.png"
import pr5 from "../../assets/images/projects/img-4.png"
import info2 from "../../assets/images/Info-1.png"
import info3 from "../../assets/images/Info-2.png"
import { IoPrintOutline } from 'react-icons/io5'
import { BsCheckCircleFill, BsInstagram, BsTwitter, BsYoutube } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'


export const WorkDetailSection = () => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full pt-[100px]">
        <div className="grid grid-cols-1 lg:grid-cols-3 md:gap-6 items-start">
          <div className="col-span-2 flex flex-col items-start gap-4">
            <div className="w-full aspect-video overflow-hidden rounded-lg">
              <img src={TeamImg} alt="service img" className="object-cover object-center w-full h-full"/>
            </div>
            <h2 className="text-troo-primaryDark text-4xl">Creative, flexible and efficient digital printing</h2>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem
              nunc. Ut sit amet velit sed sem sodales cursus quis at nibh. ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem</p>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem
              nunc. Ut sit amet velit cursus quis at nibh. </p>
            <h2 className="text-troo-primaryDark text-2xl">Client’s Requirment</h2>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu urna. Nam nec cursus velit. Praesent a sem
              nunc. Ut sit amet velit cursus quis at nibh. </p>
            <div className="grid grid-cols-1 w-full md:grid-cols-2 gap-6 items-center">
              <div className="flex flex-col items-start gap-5">
                <div className="flex items-center gap-2">
                  <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                  <p className='text-troo-primaryDark'>Every lorem ipsum sit amet just dummy.</p>
                </div>
                <div className="flex items-center gap-2">
                  <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                  <p className='text-troo-primaryDark'>Using powerful lorem ipsum dollr sit amet.</p>
                </div>
                <div className="flex items-center gap-2">
                  <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                  <p className='text-troo-primaryDark'>Lorem ipsum dollr sit amet just dummy.</p>
                </div>
                <div className="flex items-center gap-2">
                  <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                  <p className='text-troo-primaryDark'>Every lorem ipsum sit amet just dummy.</p>
                </div>
                <div className="flex items-center gap-2">
                  <BsCheckCircleFill className="text-troo-primaryDark text-xl" />
                  <p className='text-troo-primaryDark'>Using powerful lorem ipsum dollr sit amet.</p>
                </div>
              </div>
              <div className="aspect-square overflow-hidden rounded-xl">
                <img src={ServiceImg2} alt="" className='object-cover object-center w-full h-full' />
              </div>
            </div>
            <h4 className="text-troo-primaryDark text-2xl">We create some things your success future</h4>
            <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4">
              <div className="bg-troo-gray flex items-start gap-3 p-6 rounded-lg">
                <div className="aspect-square rounded-lg bg-troo-light p-3 text-troo-primaryDark text-2xl">
                  <IoPrintOutline/>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <h6 className="text-troo-primaryDark">
                    Best printing services
                  </h6>
                  <p className="text-troo-primaryDark font-light">Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum dolor sit amet elit.</p>
                </div>
              </div>
              <div className="bg-troo-gray flex items-start gap-3 p-6 rounded-lg">
                <div className="aspect-square rounded-lg bg-troo-light p-3 text-troo-primaryDark text-2xl">
                  <IoPrintOutline/>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <h6 className="text-troo-primaryDark">
                    Best services in town
                  </h6>
                  <p className="text-troo-primaryDark font-light">Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum dolor sit amet elit.</p>
                </div>
              </div>
              <div className="bg-troo-gray flex items-start gap-3 p-6 rounded-lg">
                <div className="aspect-square rounded-lg bg-troo-light p-3 text-troo-primaryDark text-2xl">
                  <IoPrintOutline/>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <h6 className="text-troo-primaryDark">
                    Successfully projects for customers
                  </h6>
                  <p className="text-troo-primaryDark font-light">Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum dolor sit amet elit.</p>
                </div>
              </div>
              <div className="bg-troo-gray flex items-start gap-3 p-6 rounded-lg">
                <div className="aspect-square rounded-lg bg-troo-light p-3 text-troo-primaryDark text-2xl">
                  <IoPrintOutline/>
                </div>
                <div className="flex flex-col items-start gap-2">
                  <h6 className="text-troo-primaryDark">
                    forprint-on demnd best services
                  </h6>
                  <p className="text-troo-primaryDark font-light">Lorem ipsum dolor sit amet, and consectetur adipiscing ipsum dolor sit amet elit.</p>
                </div>
              </div>
            </div>
            <h2 className="text-troo-primaryDark text-2xl">Let’s see project Photos</h2>
            <p className="font-light text-troo-primaryLight">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla tincidunt tincidunt nunc sed vestibulum. Suspendisse tempus,
            ipsum varius suscipit laoreet, tellus ex scelerisque nibh, at ultrices dui diam eu.</p>
            <div className="grid grid-cols-3 gap-3">
              <img src={info1} className="w-full h-full object-cover aspect-square object-center" alt="img" />
              <img src={info2} className="w-full h-full object-cover aspect-square object-center" alt="img" />
              <img src={info3} className="w-full h-full object-cover aspect-square object-center" alt="img" />
            </div>
            <div className="flex items-center gap-3 py-5 border-y border-troo-primaryDark w-full">
              <p className="text-base text-troo-primaryDark">Share This Article:</p>
              <div className="flex items-center gap-3">
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <FaFacebookF />
                </div>
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <BsTwitter />
                </div>
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <BsInstagram />
                </div>
                <div className="flex items-center justify-center p-3 text-troo-primaryDark border border-troo-primaryDark border-opacity-50 rounded-full text-sm">
                  <BsYoutube />
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-8 lg:mt-0 w-full md:grid-cols-2 lg:flex lg:flex-col items-start gap-5">
            <div className="w-full h-auto p-7 text-troo-primaryDark  bg-troo-gray flex flex-col gap-4 items-start rounded-xl">
              <h6 className='text-2xl'>Case study info</h6>
              <div className="h-[1px] w-full bg-troo-primaryDark bg-opacity-10 flex flex-col items-start gap-4">
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md">
                <span className="font-semibold">Clients:</span>  John Johnathan
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md">
                <span className="font-semibold">Category:</span>  T-shirt Printing 
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md">
                <span className="font-semibold">Date:</span> 13 March, 2023
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md">
                <span className="font-semibold">Location:</span> London, United Kingdom
              </div>
            </div>
            <div className="w-full h-auto p-7 text-troo-primaryDark  bg-troo-gray flex flex-col gap-4 items-start rounded-xl">
              <h6 className='text-2xl'>Related Projects</h6>
              <div className="h-[1px] w-full bg-troo-primaryDark bg-opacity-10 flex flex-col items-start gap-4">
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr1} alt="project 1" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr2} alt="project 2" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr3} alt="project 3" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr4} alt="project 4" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              <div className="w-full bg-troo-light p-4 rounded-md flex items-center gap-3">
                <img src={pr5} alt="project 5" />
                <p className='text-lg'>Office Wall<br/> Logo Printing</p>
              </div>
              
            </div>
            <div className="w-full relative flex flex-col items-center rounded-lg text-center bg-troo-primaryDark">
              <div className="flex absolute top-0 flex-col items-center p-8 gap-6">
                <h2 className="text-4xl text-troo-light font-bold">Ready to Work Togather</h2>
                <button className="btn-filled">Get A Quote</button>
              </div>
              <img src={WidgetImg} className='w-full h-full' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
