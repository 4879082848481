import React from 'react'
import Accordian from '../components/Accordian'

const FaqList = () => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full py-[100px] flex flex-col items-center gap-3">
        <Accordian
          bgColor="bg-troo-gray"
          title="How do I go about getting an estimate from you?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <Accordian
          bgColor="bg-troo-gray"
          title="How long will it take for you to complete my order?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <Accordian
          bgColor="bg-troo-gray"
          title="What is the best file format for submitting a document for printing?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <Accordian
          bgColor="bg-troo-gray"
          title="How well will what I see on my monitor match what I see on paper?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <Accordian
          bgColor="bg-troo-gray"
          title="What is a proof and why is it needed?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <Accordian
          bgColor="bg-troo-gray"
          title="What is the difference between coated and uncoated paper stock?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <Accordian
          bgColor="bg-troo-gray"
          title="What does 'camera ready' mean?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <Accordian
          bgColor="bg-troo-gray"
          title="What are the most common sizes for catalogs and booklets?"
          desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum." />
        <div className="relative flex flex-col z-10 items-center w-full justify-center mt-8">
					<div className="flex flex-col xl:flex-row w-full z-[1] text-troo-primaryDark xl:items-center justify-between rounded-xl bg-troo-secondary gap-4 p-8 md:p-10">
						<div className="flex flex-col items-start">
							<h4 className="font-normal">Don’t find answer?</h4>
							<h4>Contact with us on</h4>
						</div>
						<div className="flex flex-col lg:flex-row text-lg lg:items-center gap-6">
							<p>+44 123 456 7890, +44 987 654 3210</p>
							<p>example@email.com</p>
						</div>
					</div>
					<div className="absolute w-full transform scale-[.98] -bottom-3 left-0 h-full bg-troo-primaryDark rounded-xl -z-[1]"></div>
				</div>
      </div>
    </div>
  )
}

export default FaqList