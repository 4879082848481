import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo/Logo.svg";
import { NAV_ROUTES } from "../constants/navRoutes";
import { IoChatbubblesOutline } from "react-icons/io5";
import { RiMenu3Line } from "react-icons/ri";
import pencil from "../../assets/images/pencil.png"
import layers from "../../assets/images/layers.png"
import print from "../../assets/images/print.png"
import paint from "../../assets/images/paint.png"
import img from "../../assets/images/sidebar-end.png"
import MobileMenu from "./MobileMenu";

const Header = () => {
	const location = useLocation();

	return (
		<div className="drawer drawer-end w-full bg-troo-primaryLight">
			<input id="my-drawer-1" type="checkbox" className="drawer-toggle" />
			<div className="main-container-big w-full flex items-center justify-between gap-6 drawer-content">
				<img src={Logo} alt="logo" />
				<div className="hidden lg:flex items-center gap-10">
					<div className="flex w-full lg:w-auto items-center gap-8 h-full ml-4 lg:ml-0">
						{NAV_ROUTES.map((route, i) => {
							if (!route?.isMultiple) {
								return (
									<Link
										key={i}
										to={route.path}
										className={`${
											location.pathname === route.path && "activeNav"
										} relative h-full flex items-center justify-center text-troo-light py-4 text-sm lg:text-base`}
									>
										{route.name}
									</Link>
								);
							} else {
								return (
									<div
										key={i}
										className="dropdown dropdown-hover h-full flex items-center py-8 text-sm lg:text-base"
									>
										<label
											tabIndex={0}
											className="flex items-center gap-1 h-full text-troo-light"
										>
											<span>{route.name}</span> +
										</label>
										<ul
											tabIndex={0}
											className="dropdown-content p-0 w-[180px] bg-troo-light top-[100%] z-[1] menu shadow"
										>
											{route.subPages.map((subPage, i) => {
												return (
													<li
														key={i}
														className="bg-transparent hover:text-troo-secondary "
													>
														<Link
															to={subPage.path}
															className="hover:text-troo-primaryLight text-troo-primaryLight px-6 py-3 text-sm xl:text-base"
														>
															{subPage.name}
														</Link>
													</li>
												);
											})}
										</ul>
									</div>
								);
							}
						})}
					</div>
					<div className="items-center gap-3 hidden 2xl:flex">
						<div className="flex items-center justify-center bg-troo-secondary text-troo-primaryLight p-3 text-2xl rounded-full">
							<IoChatbubblesOutline />
						</div>
						<div className="flex flex-col items-start text-troo-light">
							<p>Call Us Today</p>
							<p className="font-semibold text-lg">+44 123 456 7890</p>
						</div>
					</div>
					<label htmlFor="my-drawer-1" className="drawer-overlay">
						<div className="text-troo-light text-3xl hidden lg:block">
							<RiMenu3Line />
						</div>{" "}
					</label>
				</div>
				<MobileMenu/>
			</div>
			<div className="drawer-side z-50">
				<label htmlFor="my-drawer-1" className="drawer-overlay"></label>
				<div className="menu p-6 md:p-8 box-border w-[80%] md:w-[500px] h-full overflow-scroll no-scrollbar bg-troo-light text-troo-primaryDark">
					{/* Sidebar content here */}
					<div className="flex flex-col items-start gap-10 lg:gap-6 2xl:gap-8">
						<div className="flex flex-col items-start gap-3">
							<p className="text-lg">What We Offer</p>
							<h4>
								The services we <br /> provide with perfection
							</h4>
						</div>
						<div className="flex flex-col items-start gap-10">
							<div className="flex items-center gap-3">
								<div className="bg-troo-gray p-4 rounded-full">
									<img src={pencil} alt="pencil"/>
								</div>
								<div className="flex flex-col items-start gap-2">
									<p className="hover:text-troo-secondary font-semibold text-base md:text-xl">Printing Services</p>
									<p className="line-clamp-2 text-troo-primaryDark text-sm md:text-base">Lorem ipsum dolor sit amet, consectetur  adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis.</p>
								</div>
							</div>
							<div className="flex items-center gap-3">
								<div className="bg-troo-gray p-4 rounded-full">
									<img src={layers} alt="pencil"/>
								</div>
								<div className="flex flex-col items-start gap-2">
									<p className="hover:text-troo-secondary font-semibold text-base md:text-xl">Graphics Design</p>
									<p className="line-clamp-2 text-troo-primaryDark text-sm md:text-base">Lorem ipsum dolor sit amet, consectetur  adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis.</p>
								</div>
							</div>
							<div className="flex items-center gap-3">
								<div className="bg-troo-gray p-4 rounded-full">
									<img src={print} alt="pencil"/>
								</div>
								<div className="flex flex-col items-start gap-2">
									<p className="hover:text-troo-secondary font-semibold text-base md:text-xl">Billboard Banner</p>
									<p className="line-clamp-2 text-troo-primaryDark text-sm md:text-base">Lorem ipsum dolor sit amet, consectetur  adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis.</p>
								</div>
							</div>
							<div className="flex items-center gap-3">
								<div className="bg-troo-gray p-4 rounded-full">
									<img src={paint} alt="pencil"/>
								</div>
								<div className="flex flex-col items-start gap-2">
									<p className="hover:text-troo-secondary font-semibold text-base md:text-xl">T-shirt Design Services</p>
									<p className="line-clamp-2 text-troo-primaryDark text-sm md:text-base">Lorem ipsum dolor sit amet, consectetur  adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis.</p>
								</div>
							</div>
							<div className="flex items-center gap-3">
								<div className="bg-troo-gray p-4 rounded-full">
									<img src={pencil} alt="pencil"/>
								</div>
								<div className="flex flex-col items-start gap-2">
									<p className="hover:text-troo-secondary font-semibold text-base md:text-xl">Business Card Print</p>
									<p className="line-clamp-2 text-troo-primaryDark text-sm md:text-base">Lorem ipsum dolor sit amet, consectetur  adipiscing elit. also We are Ut elit tellus, luctus nec ullamcorper mattis.</p>
								</div>
							</div>
							<button className="btn-rect-filled w-full">View All Services</button>
							<div className="flex flex-col items-start gap-2">
								<h6>Contact With US</h6>
								<p>contact@troothems.com</p>
								<p>+44 132 456 7890</p>
							</div>
							<img src={img} alt="img" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
