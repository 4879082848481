import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import FaqList from '../shared/sections/FaqList'
import ClientBrands from '../shared/sections/ClientBrands'

const Faqs = () => {
  return (
    <Layout>
      <PageHeaderSection title="Frequently Asked Questions" />
      <FaqList />
      <ClientBrands/>
    </Layout>
  )
}

export default Faqs