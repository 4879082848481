import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import GalarySection from '../shared/sections/GalarySection'
import ClientReviewSection from '../shared/sections/ClientReviewSection'
import ClientBrands from '../shared/sections/ClientBrands'

const RecentWork = () => {
  return (
    <Layout>
			<PageHeaderSection title="What We Do"/>
			<GalarySection isListingPage/>
			<ClientReviewSection />
			<ClientBrands/>
		</Layout>
  )
}

export default RecentWork