import React from 'react'
import Logo from "../../assets/images/logo-light.svg";
import { RiMenu3Line } from 'react-icons/ri'
import { NAV_ROUTES } from '../constants/navRoutes';
import { Link, useLocation } from 'react-router-dom';
import AccordianNav from './AccordianNav';

const MobileMenu = () => {
  	const location = useLocation();
  return (
    <div className="lg:hidden">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <label htmlFor="my-drawer-2" className="drawer-overlay lg:hidden">
        <div className="text-troo-light text-3xl lg:hidden py-8">
          <RiMenu3Line />
        </div>
      </label>
      <div className="drawer-side z-50">
				<label htmlFor="my-drawer-2" className="drawer-overlay"></label>
				<div className="menu p-6 md:p-8 box-border w-[80%] md:w-[500px] h-full overflow-scroll no-scrollbar bg-troo-light text-troo-primaryDark flex flex-col items-start gap-8">
          {/* Sidebar content here */}
          <img src={Logo} alt="logo" />
					<div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
							{NAV_ROUTES.map((route, i) => {
								if (!route?.isMultiple) {
									return (
										<Link
											key={i}
											to={route.path}
											className={`py-1 ${
												location.pathname === route.path && "activeMobileNav"
											}`}
										>
											{route.name}
										</Link>
									);
								} else {
									return (
										<AccordianNav
											key={i}
											title={route.name}
											subMenu={route.subPages}
										/>
									);
								}
							})}
						</div>
				</div>
			</div>
    </div>
  )
}

export default MobileMenu