import React from 'react'
import CheckText from './CheckText'
import { FaStar } from 'react-icons/fa'
import { motion } from "framer-motion";

const PriceCard = () => {
  return (
    <motion.div
      initial={{ y: 60, scale: 0.9 }}
			whileInView={{ y: 1, scale: 1 }}
      transition={{ duration: 1, type: "spring", stiffness: 100 }}
      className="w-full bg-troo-light p-10 flex flex-col items-start rounded-xl gap-6">
      <h3 className="text-troo-primaryDark font-bold text-2xl">Standard Plan</h3>
      <div className="w-full flex flex-col gap-5 sm:flex-row items-center">
        <div className="flex w-full sm:w-[50%] lg:w-[70%] flex-col items-center md:items-start gap-5 lg:border-r border-troo-tertiary border-opacity-20 pr-8">
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-y-6 gap-x-8">
            <CheckText title="Printed in full-color" />
            <CheckText title="Printed in full-color" />
            <CheckText title="Printed in full-color" isDisabled/>
            <CheckText title="Market Growth Solutions" />
            <CheckText title="Market Growth Solutions" isDisabled/>
            <CheckText title="Market Growth Solutions" isDisabled/>
            <CheckText title="Security Management" />
            <CheckText title="Security Management" isDisabled/>
            <CheckText title="Security Management" isDisabled/>
          </div>
        </div>
        <div className="w-full sm:w-[50%] lg:w-[30%] h-full flex flex-col items-center gap-4 justify-center">
          <div className="flex items-center gap-2">
            <div className='flex items-center text-troo-secondary bg-troo-secondary bg-opacity-20 rounded-md px-3 py-2 text-sm'>
              <FaStar/>
              <FaStar/>
              <FaStar/>
              <FaStar/>
              <FaStar/>
            </div>
            <p className="text-sm text-troo-primaryLight">( Save upto25% )</p>
          </div>
          <div className="text-sm flex items-end gap-2">
            <h5 className="text-4xl text-troo-primaryDark">$24.00</h5>
            <p className="text-troo-primaryDark font-light text-lg">/Monthly</p>
          </div>
          <button className="btn-filled">Choose This Plan</button>
        </div>
      </div>
    </motion.div>
  )
}

export default PriceCard