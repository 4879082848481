import React from 'react'
import Heading from '../components/Heading'
import aboutImg from "../../assets/images/about.png"
import { BsCheckCircleFill } from 'react-icons/bs'
import avatar1 from "../../assets/images/avatar1.png"
import avatar2 from "../../assets/images/avatar2.png"
import { motion } from "framer-motion";
import { Link } from 'react-router-dom'
import { ROUTES } from '../constants/routes'

const AboutSection = () => {
  return (
    <div className="w-full bg-troo-light">
      <div className="main-container w-full py-[100px] grid grid-cols-1 lg:grid-cols-2 justify-between gap-12">
        <motion.div initial={{ scale: 0.7 }}
			whileInView={{ scale: 1 }}
			transition={{ duration: 1, type: "spring", stiffness: 100 }} className="w-full h-auto overflow-hidden">
          <img src={aboutImg} alt="About Img" className='w-full h-full object-contain'/>
        </motion.div>
        <div className="flex flex-col items-start gap-4">
          <Heading headline="Learn somthing more" heading={ 
            <>
              Providing the best printin <br/>
              solutions for your business
            </>
          } />
          <p className="text-troo-primaryLight text-xl">Lorem Ipsum is simply dummy text we it of the Lorem Ipsum is simply dummy text theprinting and typesetting.</p>
          <p className="text-troo-primaryLight">Lorem Ipsum is simply dummy text of tiaot you he Lorem Ipsum is simply dummy text  theprinting and typesetting industryprinting and typesetting industry Lorem Ipsum is simply dummy text of the Lorem Ipsum is simply dummy text  theprinting and typesetting industryprinting and typesetting industry.</p>
          <div className="flex flex-col items-start gap-3">
            <div className="flex items-center gap-2 text-troo-primaryLight">
              <div>
                <BsCheckCircleFill className="text-xl bg-troo-secondary rounded-full"/>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </div>
            <div className="flex items-center gap-2 text-troo-primaryLight">
              <div>
                <BsCheckCircleFill className="text-xl bg-troo-secondary rounded-full"/>
              </div>
              <p>Lorem ipsum dolor sit amet.</p>
            </div>
            <div className="flex items-center gap-2 text-troo-primaryLight">
              <div>
                <BsCheckCircleFill className="text-xl bg-troo-secondary rounded-full"/>
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur.</p>
            </div>
            <div className="flex items-center gap-2 text-troo-primaryLight">
              <div>
                <BsCheckCircleFill className="text-xl bg-troo-secondary rounded-full"/>
              </div>
              <p>There Is Lorem ipsum dolor sit amet, Lorem ipsum.</p>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start lg:items-center gap-4 mt-5">
            <Link to={ROUTES.aboutUs}>
              <button className="btn-filled">Learn More</button>
            </Link>
            <div className="flex items-center gap-3 bg-troo-primaryDark bg-opacity-5 p-6">
              <div className="flex items-center">
                <img src={avatar1} alt="avatar 1"  />
                <img src={avatar2} alt="avatar 2" className='-ml-3' />
                <div className="w-[30px] h-[30px] bg-troo-primaryDark text-troo-light flex items-center justify-center rounded-full -ml-3 text-sm">5K</div>
              </div>
              <div className="flex flex-col items-start text-troo-primaryDark">
                <p className="text-xl font-bold">Our Satisfied client</p>
                <p className="text-sm font-light">has been 30 years until now</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection