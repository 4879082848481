export const ROUTES = {
	home: "/",
	aboutUs: "/about-us",
	ourServices: "/our-services",
	serviceDetails: "/our-services/service-details",
	recentWork: "/recent-work",
	portfolioDetails: "/portfolio/portfolio-details",
	ourTeam: "/our-team",
	teamDetails: "/team/team-details",
	blogsGrid: "/blogs",
	blogsList: "/blogs-list",
	blogDetails: "/blogs/blog-details",
	faq: "/faq",
	pricing: "/pricing",
	testimonial: "/testimonial",
	contactPage: "/contact-us",
	makeAnAppointment: "/make-an-appointment",
	errorPage: "/404",
	comingSoon: "/coming-soon",
};
