import { ROUTES } from "./routes";

export const NAV_ROUTES = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		name: "Services",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.ourServices,
				name: "Service",
			},
			{
				path: ROUTES.serviceDetails,
				name: "Service Detail",
			},
		],
	},
	{
		name: "Pages",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.ourTeam,
				name: "Team",
			},
			{
				path: ROUTES.teamDetails,
				name: "Team Detail",
			},
			{
				path: ROUTES.testimonial,
				name: "Testimonial",
			},
			{
				path: ROUTES.faq,
				name: "FAQ",
			},
			{
				path: ROUTES.pricing,
				name: "Pricing",
			},
			{
				path: ROUTES.errorPage,
				name: "404",
			},
			{
				path: ROUTES.comingSoon,
				name: "Coming Soon",
			},
		],
	},
	{
		name: "Portfolio",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.recentWork,
				name: "Portfolio",
			},
			{
				path: ROUTES.portfolioDetails,
				name: "Portfolio Detail",
			},
		],
	},
	{
		name: "Blogs",
		isMultiple: true,
		subPages: [
			{
				path: ROUTES.blogsGrid,
				name: "Blogs",
			},
			{
				path: ROUTES.blogDetails,
				name: "Blog Details",
			},
		],
	},
	{
		path: ROUTES.contactPage,
		name: "Contact Us",
	},
];

export const FOOTER_NAV = [
	{
		path: ROUTES.home,
		name: "Home",
	},
	{
		path: ROUTES.aboutUs,
		name: "About Us",
	},
	{
		path: ROUTES.services,
		name: "Services",
	},
	{
		path: ROUTES.portfolio,
		name: "Portfolio",
	},
	{
		path: ROUTES.blogsGrid,
		name: "News",
	},
	{
		path: ROUTES.contactPage,
		name: "Contact us",
	},
];
