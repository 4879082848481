import React from 'react'
import Heading from '../components/Heading'
import PriceCard from '../components/PriceCard'
import { ROUTES } from '../constants/routes'
import { Link } from 'react-router-dom'

const PricingSection = ({isListingPage}) => {
  return (
    <div className="w-full bg-troo-gray">
      <div className="main-container w-full py-[100px] flex flex-col items-center gap-12">
        {
          !isListingPage && (
            <div className="w-full flex flex-col lg:flex-row items-center justify-between gap-4">
              <Heading headline="Our Pricing Plan" heading={ 
                <>
                  Unmatched Features With Transparent Pricing
                </>
              } />
              <div className="flex items-center gap-4">
                <p className="text-sm font-light">
                  Lorem Ipsum is simply dummy text of tiaot you he Lorem Ipsum is simply dummy text theprinting and typesetting industryprinting and typesetting industry Lorem Ipsum is simply dummy.
                </p>
                <Link to={ROUTES.pricing}>
                  <button className="btn-outlined-dark whitespace-nowrap">View All Plans</button>
                </Link>
              </div>
            </div>
          )
        }
        <div className="w-full flex flex-col items-start gap-5">
          <PriceCard/>
          <PriceCard/>
          <PriceCard />
          {
            isListingPage && (
              <>
                <PriceCard/>
                <PriceCard/>
                <PriceCard />
              </>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default PricingSection