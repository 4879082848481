import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import PricingSection from '../shared/sections/PricingSection'
import ClientBrands from '../shared/sections/ClientBrands'

const Pricing = () => {
  return (
    <Layout>
      <PageHeaderSection title="Affordable Price for You."/>
      <PricingSection isListingPage />
      <ClientBrands/>
		</Layout>
  )
}

export default Pricing