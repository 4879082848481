import React from 'react'
import Layout from '../shared/components/Layout'
import PageHeaderSection from '../shared/sections/PageHeaderSection'
import ServiceSection from '../shared/sections/ServiceSection'
import TeamSection from '../shared/sections/TeamSection'
import ContactBanner from '../shared/sections/ContactBanner'
import ClientReviewSection from '../shared/sections/ClientReviewSection'
import ClientBrands from '../shared/sections/ClientBrands'

const OurServices = () => {
  return (
    <Layout>
			<PageHeaderSection title="What We Do"/>
			<ServiceSection isListingPage />
			<TeamSection />
			<ContactBanner/>
			<ClientReviewSection />
			<ClientBrands/>
		</Layout>
  )
}

export default OurServices