import React, { useState } from "react";

const Accordian = ({ title, desc, bgColor }) => {
	const [isActive, setIsActive] = useState(false);

	const handleAccordionClick = (e) => {
		setIsActive(e.target.checked && !isActive);
	};

	return (
		<div
			className={`collapse collapse-plus cursor-pointer rounded-none ${
				isActive
					? "bg-troo-gray collapse-open"
					: `${
							bgColor || "bg-troo-secondary"
					  } text-troo-primaryDark collapse-close`
			}`}
		>
			<input type="radio" name="accordian" onClick={handleAccordionClick} />
			<h6 className="collapse-title px-7">{`${title}`}</h6>
			<div className="collapse-content px-7">
				<p>{desc}</p>
			</div>
		</div>
	);
};

export default Accordian;
