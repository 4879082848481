import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ banner, children, errorPage }) => {
	return (
		<>
			<Header />
			<main>{children}</main>
			{!errorPage && <Footer />}
		</>
	);
};

export default Layout;
