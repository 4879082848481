import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ROUTES } from "../constants/routes";

const Breadcrumbs = () => {
	const location = useLocation();
	let nav = location.pathname
		?.replace(/\?.+/, "")
		?.split("/")
		?.filter((x) => x);

	return (
		<div className="text-sm breadcrumbs">
			<ul>
				<li>
					<Link to={ROUTES.home}>Home</Link>
				</li>
				{nav.map((x, i) => (
					<li key={x}>
						<Link
							to={`/${x}`}
							className={`capitalize ${
								i === nav.length - 1 && "text-troo-tertiary pointer-events-none"
							}`}
						>
							{x?.replace(/-/gi, " ")}
						</Link>
					</li>
				))}
			</ul>
		</div>
	);
};

export default Breadcrumbs;